<template>
  <v-container fluid>
    <div class="tw-text-2xl tw-font-bold tw-leading-tight tw-text-black dark:tw-text-white tw-mb-2">
      {{ formTitle }}
    </div>
    <div class="tw-flex tw-items-center dark:tw-text-gray-100 tw-text-xs">
      <span
        v-for="(item, index) in breadcrumbs"
        :key="item.routeName"
      >
        <router-link :to="{ name: item.routeName }">{{ item.title }}</router-link>
        <span
          v-if="index < breadcrumbs.length - 1"
          class="tw-mx-2"
        >&gt;</span>
      </span>
    </div>
  </v-container>
</template>

<script>

export default {
    name: 'FormHeaderWithBreadcrumbs',
    props: {
        formTitle: {
            type: String,
            default: 'defaultFormName',
        },
        breadcrumbs: {
            type: Array,
            default: () => [
                {
                    title: 'Phox',
                    routeName: 'home',
                },
            ],
        },
    },
    setup() {
        return {
        }
    },
    computed: {},
    methods: {},
}
</script>
